import React from 'react'
import {
  CheckMarkIcon,
  CartIcon,
  HamburgerIcon,
  SearchIcon,
  ChatIcon,
  LockOpenIcon,
  LockClosedIcon,
  MailIcon,
  PlaceIcon,
  LikeIcon,
  ChatActiveIcon,
  MinusIcon,
  PlusIcon,
  CartFilledIcon,
  Logo,
  IconLogo,
  InfoIcon,
  NewPlaceIcon,
  AssociationMagazineLogo,
  MagazineLogo,
  LogoNegative,
  Dots,
  CircledHeart,
  MaterialIconWarning,
  FlagSweden,
  FlagFinland,
} from '../components/Svg/svg'

/**
 * Get Icon
 * @param {*} icon
 */
export const getSvgIcon = (icon, color = 'black', width = 20) => {
  let IconComponent = null

  switch (icon) {
    case 'checkMark':
      IconComponent = (
        <CheckMarkIcon id={'checkMark'} width={width} color={color} />
      )
      break
    case 'cart':
      IconComponent = <CartIcon id={'cart'} width={width} color={color} />
      break
    case 'cartFilled':
      IconComponent = (
        <CartFilledIcon id={'cartFilled'} width={width} color={color} />
      )
      break
    case 'hamburger':
      IconComponent = (
        <HamburgerIcon id={'hamburger'} width={width} color={color} />
      )
      break
    case 'search':
      IconComponent = <SearchIcon id={'search'} width={width} color={color} />
      break
    case 'chat':
      IconComponent = <ChatIcon id={'chat'} width={width} color={color} />
      break
    case 'lockOpen':
      IconComponent = (
        <LockOpenIcon id={'lockOpen'} width={width} color={color} />
      )
      break
    case 'lockClosed':
      IconComponent = (
        <LockClosedIcon id={'lockClosed'} width={width} color={color} />
      )
      break
    case 'mail':
      IconComponent = <MailIcon id={'mail'} width={width} color={color} />
      break
    case 'place':
      IconComponent = <PlaceIcon id={'place'} width={width} color={color} />
      break
    case 'like':
      IconComponent = <LikeIcon id={'like'} width={width} color={color} />
      break
    case 'chatActive':
      IconComponent = (
        <ChatActiveIcon id={'chatActive'} width={width} color={color} />
      )
      break
    case 'minus':
      IconComponent = <MinusIcon id={'minus'} width={width} color={color} />
      break
    case 'plus':
      IconComponent = <PlusIcon id={'plus'} width={width} color={color} />
      break
    case 'fullLogotype':
      IconComponent = <Logo id={'fullLogotype'} width={width} color={color} />
      break
    case 'iconLogotype':
      IconComponent = (
        <IconLogo id={'iconLogotype'} width={width} color={color} />
      )
      break
    case 'info':
      IconComponent = <InfoIcon id={'infoIcon'} width={width} color={color} />
      break
    case 'newPlace':
      IconComponent = (
        <NewPlaceIcon id={'newPlaceIcon'} width={width} color={color} />
      )
      break
    case 'associationMagazineLogo':
      IconComponent = (
        <AssociationMagazineLogo id={'associationMagazineLogo'} color={color} />
      )
      break
    case 'magazineLogo':
      IconComponent = <MagazineLogo id={'magazineLogo'} color={color} />
      break

    case 'fullLogotypeNeg':
      IconComponent = (
        <LogoNegative id={'fullLogotypeNeg'} width={width} color={color} />
      )
      break
    case 'dots':
      IconComponent = (
        <Dots id={'fullLogotypeNeg'} width={width} color={color} />
      )
      break
    case 'MaterialIconWarning':
      IconComponent = (
        <MaterialIconWarning id={'MaterialIconWarning'} width={width} />
      )
      break
    case 'CircledHeart':
      IconComponent = <CircledHeart id={'CircledHeart'} width={width} />
    case 'FlagSweden':
      IconComponent = (
        <FlagSweden id={'fullLogotypeNeg'} width={width} color={color} />
      )
      break
    case 'FlagFinland':
      IconComponent = (
        <FlagFinland id={'fullLogotypeNeg'} width={width} color={color} />
      )
      break
    default:
      IconComponent = <NoIcon />
  }
  return IconComponent
}

const NoIcon = () => {
  return <span></span>
}
