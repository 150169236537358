import { getCookie, setCookie } from './storage'
import { getIsClient } from './utilities'

/**
 * Get slug
 * @param {*} url
 */
export const getSlug = url => {
  return url.substring(url.indexOf('/', 8))
}

/**
 * Path prefix
 */
export const PATH_PREFIX = process.env.GATSBY_PATH_PREFIX
  ? process.env.GATSBY_PATH_PREFIX
  : `/`

/**
 * Get filename
 * @param {*} url
 */
export const getFileName = url => {
  return url.split(/(\\|\/)/g).pop()
}

/**
 * Fix path
 * @param {*} path
 */
const preparePath = path => {
  const parsePath = checkTrailingSlash(path)
  return [
    'front-page',
    'home',
    'startsida',
    '/front-page/',
    '/home/',
    '/startsida/',
  ].includes(parsePath)
    ? '/'
    : parsePath
}

/**
 * Site url
 */
export const SITE_URL = process.env.GATSBY_SITE_URL
  ? process.env.GATSBY_SITE_URL
  : 'https://www.newbodyfamily.com/sv-se'

export const CLEAN_ABSOLUTE_URL = process.env.GATSBY_CLEAN_ABSOLUTE_URL || false

/**
 * Parse url
 * @param {*} url
 * @param {*} onlyRelative
 */
export const getUrl = (url, onlyRelative = false) => {
  try {
    // Shop link
    if (url.indexOf('/shop/') >= 0) {
      // Fix relative
      if (url.startsWith('/')) return SITE_URL + url
      return url
    }
    // Cuphittaren link
    if (url.indexOf('/cuphittaren/') >= 0) {
      // Fix relative
      if (url.startsWith('/')) return SITE_URL + url
      return url
    }

    if (url.startsWith('/')) return preparePath(url)
    if (url.indexOf('/shop/') >= 0) return url // Shop link
    if (url.indexOf('/cuphittaren/') >= 0) return url // Cuphittaren link
    if (!onlyRelative) {
      if (url.startsWith('#')) return url // Anchor link
      if (url.indexOf('/uploads/') >= 0) return parseUploadsUrl(url) // Asset
      if (
        process.env.GATSBY_CMS_URL &&
        !url.startsWith(process.env.GATSBY_CMS_URL) &&
        SITE_URL &&
        !url.startsWith(SITE_URL)
      ) {
        if (CLEAN_ABSOLUTE_URL) {
          url = url.replace(CLEAN_ABSOLUTE_URL, '')
        }
        return url // External??
      }
    }

    // Fix sub path for gatsby link
    if (PATH_PREFIX !== '/' && url.indexOf(PATH_PREFIX) >= 0)
      url = url.replace(PATH_PREFIX, '/')

    const path = getPath(url)
    return preparePath(path)
  } catch (error) {
    return url
  }
}

/**
 * Add trailing slash if none
 * @param {*} path
 */
const checkTrailingSlash = path => {
  return path.length && path.substring(path.length - 1) !== '/'
    ? path + '/'
    : path
}

/**
 * Parse uploads URL
 * @param {*} url
 */
const parseUploadsUrl = url => {
  if (process.env.GATSBY_CMS_URL && SITE_URL) {
    if (url.startsWith('/')) return process.env.GATSBY_CMS_URL + url
    return url.replace(SITE_URL, process.env.GATSBY_CMS_URL)
  }
  return url
}

/**
 * Get path
 * @param {*} url
 */
const getPath = url => {
  var match = url.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  )
  return match && match[5]
}

/**
 * Create url for shop
 *  @param {*} url
 */
export const createShopUrl = url => {
  const shopUrl = `https://www.newbodyfamily.com${PATH_PREFIX}shop${url}`
  return shopUrl
}

/**
 * Check if url needs special access and if user has access
 * @param {string} url -- url to check
 */
export const isSpecialAccess = () => {
  if (!process.env.GATSBY_SPECIAL_ACCESS_TOKEN) return false
  const hasAccess = getIsClient() && getCookie('specialAccess') === 'true'
  return !hasAccess
}

/**
 * Set Spcial Access Cookie
 */
export const setSpecialAccessCookie = () => {
  setCookie('specialAccess', 'true', 1)
}

/**
 *
 */
