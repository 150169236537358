import React from "react"
import { doTrackLink } from "../../helpers/tracking"
import StyledGatsbyLink from './style'
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, handleClick, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others

  // Middleware for click
  const doHandleClick = () => {
    if (handleClick) handleClick()
    if (to) doTrackLink(to)
  }

  if (internal) {
    return (
      <StyledGatsbyLink
        onClick={doHandleClick}
        to={to}
        activeClassName={activeClassName ?? 'active'}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </StyledGatsbyLink>
    )
  }
  return (
    <a href={to} {...other} onClick={doHandleClick}>
      {children}
    </a>
  )
}
export { Link }