import { FORM_TOKEN, API_URL } from '../../node/functions'
import { getCurrentLang } from './lang'

const checkStatus = response => {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

export const sendInterestForm = async data => {
  data.token = FORM_TOKEN
  return await await fetch(`${API_URL}/interest`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const updateInterestForm = async data => {
  data.token = FORM_TOKEN
  return await await fetch(`${API_URL}/interestUpdate`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const sendNewsletterForm = async data => {
  data.token = FORM_TOKEN
  return await await fetch(`${API_URL}/newsletter`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const sendChangeForm = async data => {
  data.token = FORM_TOKEN
  return await await fetch(`${API_URL}/change`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const sendReturnForm = async data => {
  data.token = FORM_TOKEN
  return await await fetch(`${API_URL}/return`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const fetchFormData = async () => {
  return await await fetch(`${API_URL}/data`, {
    method: 'get',
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const sendForm = async (baseUrl, formData) => {
  let functionsData = {
    baseUrl: baseUrl,
    formData: formData,
  }
  return await await fetch(`${API_URL}/form`, {
    method: 'post',
    body: JSON.stringify(functionsData),
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const fetchEmailLog = async data => {
  const emailLogEndpoint = process.env.GATSBY_EMAIL_LOG_ENDPOINT
  if (emailLogEndpoint) {
    return await await fetch(emailLogEndpoint, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(res => res.json())
  }
}

export const validateAddress = async address => {
  const data = {
    ...address,
    currentLang: getCurrentLang(),
  }
  return await await fetch(`${API_URL}/address`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

/**
 * Retry request
 * @param {*} fn
 * @param {*} currentRetry
 * @param {*} maxRetries
 * @param {*} interval
 */
export const retryRequest = (
  fn,
  currentRetry = 0,
  maxRetries = 1,
  interval = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (currentRetry === maxRetries) {
            reject(error)
            return
          }
          retryRequest(fn, currentRetry + 1, maxRetries, interval).then(
            resolve,
            reject
          )
        }, interval)
      })
  })
}
