export const BASE_UNIT = 'rem'
export const BASE_VALUE = BASE_UNIT === 'px' ? 10 : 1

const calcValue = (value, divided = false) => {
  return divided ? BASE_VALUE / value : BASE_VALUE * value
}

export const headerHeights = {
  desktop: '11rem',
  mobile: '10rem',
}

/////////////////////////////////
// Colors
/////////////////////////////////
export const colors = {
  primary: '#1B1B1B',
  secondary: '#E1DBC9',

  // Normal colors
  white: '#FFFFFF',
  black: '#1B1B1B',
  darkGrey: '#494949',
  green: '#464D3D',
  brown: '#74523D',
  red: '#f4354a',
  yellow: '#EAB449',

  // Grey colors
  grey: '#727272',
  lightGrey: '#C8C7C7',
  lightestGrey: '#EEEEEE',

  // Branding colors
  brandGreen: '#464D3D',
  brandOrange: '#DA9262',
  brandBeige: '#BAAB9C',

  // Alert colors
  alertRed: '#DD5F55',
  alertYellow: '#F7DE81',
  alertGreen: '#73C286',

  // Newbody-color
  newbody: '#BAAB9D', // For sanity
  nbOne: '#BAAB9D',
  nbTwo: '#38312C',
  nbThree: '#74523D',
  nbFour: '#867669',
  nbFive: '#BFA28A',
  nbSix: '#E1DBC9',

  // Newbody color
  homeSeremonies: '#454E3E', // For sanity
  hsOne: '#454E3E',
  hsTwo: '#2C3125',
  hsThree: '#68715D',
  hsFour: '#818372',
  hsFive: '#CEBCAA',
  hsSix: '#DBDBDB',

  // New colors
  white_02: '#FFFDFA',
  lightGreen: '#86967A',
  lightGreen_02: '#22543F',
  forestGreen: '#193D2E',
  darkGreen: '#133024',
  darkPink: '#ED6763',
  pink: '#F3B2A3',
  brightYellow: '#FCEC50',
  lightYellow: '#FFFBD3',
  successGreen: '#1F9564',
  lightBlue: '#95b0c2',
  mediumBlue: '#326187',
  darkestBlue: '#0D1E31',
  blue: '#16345f',
  brandBlue: '#091d33',
  darkBlue: '#011726',

  pinkTheme: {
    primary: '#ED6763',
    secondary: '#F3B2A3',
  },
  greenTheme: {
    primary: '#133024',
    secondary: '#FCEC50',
  },
  greenThemeV2: {
    primary: '#fff',
    secondary: '#133024',
  },
  standardTheme: {
    primary: '#2b2b2b',
    secondary: '#3c3c3c',
  },
  blueTheme: {
    primary: '#0D1E31',
    secondary: '#95b0c2',
  },
  beigeTheme: {
    primary: '#1B1B1B',
    secondary: '#E1DBC9',
  },

  // Spicy dream color
  spicyDream: '#DA9262', // For sanity
  sdOne: '#DA9262',

  graphite: '#575757',

  imageBackgroundColor: '#F6F6F6',
  borderColor: '#D1D1D1',
  placeholder: 'rgba(0,0,0,0.7)',
  transparent: 'transparent',
}

export const boxShadow = 'rgba(0, 0, 0, 0.45) 0px 0px 20px'

/////////////////////////////////
// Spacing
/////////////////////////////////
export const spacing = {
  xxxLarge: `${calcValue(10)}${BASE_UNIT}`,
  xxLarge: `${calcValue(6.4)}${BASE_UNIT}`,
  xLarge: `${calcValue(4.8)}${BASE_UNIT}`,
  large: `${calcValue(3.2)}${BASE_UNIT}`,
  medium: `${calcValue(2.4)}${BASE_UNIT}`,
  midBase: `${calcValue(2)}${BASE_UNIT}`,
  base: `${calcValue(1.6)}${BASE_UNIT}`,
  small: `${calcValue(1.2)}${BASE_UNIT}`,
  xSmall: `${calcValue(0.8)}${BASE_UNIT}`,
  xxSmall: `${calcValue(0.4)}${BASE_UNIT}`,
  xxxSmall: `${calcValue(0.2)}${BASE_UNIT}`,
  //new
  _88: `${calcValue(8.8)}${BASE_UNIT}`,
}

/////////////////////////////////
// Typography
/////////////////////////////////
export const typography = {
  primaryFont: 'Galanogrotesque',
  secondaryFont: 'Galanogrotesque',
  MatrisePro: 'MatrisePro',

  lineHeight: '1.2',
  lineHeights: {
    large: `1.5`,
    base: `1.2`,
    small: `1`,
  },
  sizes: {
    xxxLarge: `${calcValue(9.6)}${BASE_UNIT}`,
    xxLarge: `${calcValue(6.4)}${BASE_UNIT}`,
    xLarge: `${calcValue(4.8)}${BASE_UNIT}`,
    large: `${calcValue(3.2)}${BASE_UNIT}`,
    medium: `${calcValue(2.4)}${BASE_UNIT}`,
    base: `${calcValue(2)}${BASE_UNIT}`,
    midBase: `${calcValue(1.8)}${BASE_UNIT}`,
    small: `${calcValue(1.6)}${BASE_UNIT}`,
    xSmall: `${calcValue(1.4)}${BASE_UNIT}`,
    xxSmall: `${calcValue(1.3)}${BASE_UNIT}`,
    xxxSmall: `${calcValue(1.1)}${BASE_UNIT}`,
    preamble: `${calcValue(2.2)}${BASE_UNIT}`,
    //new
    _12: `${calcValue(1.2)}${BASE_UNIT}`,
    _72: `${calcValue(7.2)}${BASE_UNIT}`,
    _88: `${calcValue(8.8)}${BASE_UNIT}`,
  },
}

export const radius = {
  small: `${calcValue(0.2)}${BASE_UNIT}`,
  medium: `${calcValue(0.8)}${BASE_UNIT}`,
  input: `${calcValue(1)}${BASE_UNIT}`,
  large: `${calcValue(3)}${BASE_UNIT}`,
}

export const maxWidths = {
  headings: '800px',
  base: '650px',
  input: '510px',
  container: '1200px',
  wideContainer: '1440px',
  narrowContainer: '880px',
  narrowestContainer: '680px',
}

/////////////////////////////////
// Breakpoints
/////////////////////////////////
export const mqSizes = {
  desktopxLarge: 1600,
  desktopLarge: 1440,
  desktop: 1200,
  tablet: 1024,
  smallTablet: 900,
  mobile: 768,
  mobileSmall: 480,
}

export const mq = {
  tablet: `(max-width: ${mqSizes.tablet}px)`,
  mobile: `(max-width: ${mqSizes.mobile}px)`,
  mobileSmall: `(max-width: ${mqSizes.mobileSmall}px)`,
  smallTablet: `(max-width: 900px)`,
  desktopMin: `(min-width: ${mqSizes.mobile + 1}px)`,
  tabletMin: `(min-width: ${mqSizes.tablet + 1}px)`,
  desktop: `(max-width: ${mqSizes.desktop}px)`,
  desktopLarge: `(min-width: ${mqSizes.desktopLarge}px)`,
  desktopxLarge: `(min-width: ${mqSizes.desktopxLarge}px)`,
}

export const easeConfig = {
  default: { mass: 1, tension: 500, friction: 40, clamp: true },
}

export const cssEasings = {
  // In
  easeInSine: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
  easeInQuad: 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  easeInCubic: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  easeInQuart: 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
  easeInQuint: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  easeInExpo: 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  easeInCirc: 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
  easeInBack: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',

  // Out
  easeOutSine: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
  easeOutQuad: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  easeOutCubic: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  easeOutQuart: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  easeOutQuint: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  easeOutExpo: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  easeOutCirc: 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',

  // In-Out
  easeInOutSine: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
  easeInOutQuad: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  easeInOutQuart: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
  easeInOutQuint: 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
  easeInOutExpo: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
  easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
  easeInOutBack: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
}

/////////////////////////////////
// Directions
/////////////////////////////////
export const directions = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  topLeft: 'top left',
  topRight: 'top right',
  bottomLeft: 'bottom left',
  bottomRight: 'bottom left',
}

/////////////////////////////////
// Image effect groups
/////////////////////////////////
export const imageEffectGroups = {
  demo: {
    useBlur: true,
    effectDirection: directions.topRight,
    color: colors.red,
    darken: '40%',
    grayscale: '20%',
  },
  demo2: {
    useBlur: true,
    effectDirection: directions.topLeft,
    grayscale: '70%',
    darken: '140%',
  },
  blackAndWhite: {
    useBlur: true,
    effectDirection: directions.topRight,
    makeBlackAndWhite: true,
    darken: '80%',
  },
}
