import styled from 'styled-components'
import { colors } from '../../styles/utilities/variables'

const StyledSvg = styled.div`
  font-size: 0;
  line-height: 1;
  display: inline-block;

  div {
    display: inline-block;
  }

  svg {
    width: ${({ size }) => (size ? size + 'px' : '24px')};
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    stroke: currentColor;
    color: currentColor;
    fill: none;
  }

  .use-cover-fill-color {
    fill: ${props => props.coverFillColor || '#ffffff'};
  }
`
export const StyledCircleHeart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 8rem;
  height: 8rem;
  background-color: ${colors.pink};
  svg {
    margin-top: 3px;
  }
`

export default StyledSvg
