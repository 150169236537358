import React from 'react'
import PropTypes from 'prop-types'
import StyledHeading from './style'

const types = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

/**
 * Heading
 * @param {*} props
 */
const Heading = props => {
  const {
    size,
    type,
    children,
    align,
    anchor,
    light,
    color,
    lineHeight,
  } = props
  //const headingSize = size ? size : 2
  const headingType = type ? type : 'h2'
  const Component = StyledHeading[headingType]
  return (
    <Component
      align={align}
      size={size}
      id={anchor ? anchor : null}
      light={light}
      type={type}
      color={color}
      lineHeight={lineHeight}
    >
      {children}
    </Component>
  )
}
Heading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(types),
  children: PropTypes.any,
  anchor: PropTypes.string,
  light: PropTypes.bool,
}

export { Heading }
