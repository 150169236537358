import React from 'react'
import PropTypes from 'prop-types'
import StyledWrapper from './style'

const widths = {
  small: 50,
  medium: 100,
  large: 120,
}

const Wrapper = props => {
  const { children, size, handleClick, innerRef } = props
  const width = size ? widths[size] : null

  return (
    <StyledWrapper
      ref={innerRef}
      {...props}
      width={width}
      onClick={handleClick}
    >
      {children}
    </StyledWrapper>
  )
}

const spacingPropType = PropTypes.oneOfType([
  'xxxLarge',
  'xxLarge',
  'xLarge',
  'large',
  'medium',
  'base',
  'small',
  'xSmall',
  'xxSmall',
])

Wrapper.propTypes = {
  children: PropTypes.any,
  flexWrap: PropTypes.bool,
  rowGap: spacingPropType,
  columnGap: spacingPropType,
  gap: spacingPropType,
  responsive: PropTypes.bool,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  direction: PropTypes.oneOf(['column', 'row-reverse', 'column-reverse']),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  spacing: PropTypes.oneOf([
    'massive',
    'huge',
    'xLarge',
    'large',
    'medium',
    'base',
    'small',
    'tiny',
    'vapor',
  ]),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
}

export { Wrapper }
