import { GTM_ID, PATH_PREFIX } from '../../node/functions'
import { SITE_URL } from './url'
import { hash } from './utilities'

/**
 * Push to data layer
 */
export const pushDataLayer = event => {
  // Add source of not present
  if (event && !event.source) event.source = 'Info Web'
  if (window.cookiebotAccept == true) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
  } else {
    window.tempDataLayer = window.tempDataLayer || []
    window.tempDataLayer.push(event)
  }
}

/**
 * Is ads page?
 */
const getIsAdsPage = () => {
  return window.location.href.indexOf('/ads/') >= 0
}

/**
 * Track interest form
 * @param {*} brand
 * @param {*} type
 * @param {*} email
 * @param {*} category
 */
export const doTrackInterestForm = async (brand, type, email, category) => {
  const formType = getIsAdsPage() ? 'Landing Page Ads' : 'Web Page Interest'
  const event = {
    event: 'formSubmission',
    formType: formType,
    topic: getTranslation(type),
    brand: getTranslation(brand),
    fb: 'Facebook Lead',
    category: category,
    customer_email: email,
    customer_email_sha256: hash(email),
  }
  pushDataLayer(event)
}

/**
 * Track interest form complement
 * @param {*} brand
 * @param {*} type
 */
export const doTrackInterestFormComplement = async (brand, type) => {
  const formType = getIsAdsPage() ? 'Landing Page Ads' : 'Web Page Interest'
  const event = {
    event: 'formSubmission',
    formType: formType,
    topic: type ? getTranslation(type) + ' Complement' : '',
    brand: brand ? getTranslation(brand) : '',
    fb: 'Facebook Lead',
  }
  pushDataLayer(event)
}

/**
 * Track interest form impression
 */
export const doTrackInterestFormImpression = async () => {
  const event = {
    event: 'viewed_sales_request_form',
  }
  pushDataLayer(event)
}

/**
 * Track interest form started
 */
export const doTrackInterestFormStarted = async () => {
  const event = {
    event: 'started_sales_request_form',
  }
  pushDataLayer(event)
}

/**
 * Track link click
 * @param {*} to
 */
export const doTrackLink = async to => {
  // Fix subpath
  const href =
    PATH_PREFIX !== '/' && to.startsWith('/') && !to.startsWith(PATH_PREFIX)
      ? to.replace('/', PATH_PREFIX)
      : to
  pushDataLayer({
    event: 'linkClick',
    clickURL: href.startsWith('/') ? getBaseUrl() + href : href,
  })
}

/**
 * Base URL
 */
const getBaseUrl = () => {
  const pathArray = SITE_URL.split('/')
  const protocol = pathArray[0]
  const host = pathArray[2]
  return protocol + '//' + host
}

/**
 * Track page view
 */
let originalLocation = null
export const doTrackPageView = async () => {
  // Init value
  if (!originalLocation) {
    originalLocation =
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search
  }
  const event = {
    originalLocation: originalLocation,
  }
  pushDataLayer(event)
}

/**
 * Track start chat
 */
let chatEventInitialized = false
export const doTrackChat = async () => {
  const addStartChatListener = () => {
    chatEventInitialized = true
    window.HubSpotConversations.on('conversationStarted', payload => {
      const chatType = getIsAdsPage() ? 'Landing Page Ads' : 'Web Page Interest'
      pushDataLayer({
        event: 'chatInteraction',
        chatType: chatType,
      })
    })
  }

  if (!chatEventInitialized && window.HubSpotConversations) {
    addStartChatListener()
  }
}

/**
 * Get translation
 * @param {*} key
 */
const getTranslation = key => {
  return TRACKING_TRANSLATION[key] ?? key
}

/**
 * Translation keys
 */
const TRACKING_TRANSLATION = {
  SALES_REQUEST: 'Sales Request',
  SAMPLE_REQUEST: 'Sample Request',
  NEWBODY: 'Newbody',
  SPICY_DREAM: 'SpicyDream',
}

/**
 * Is tracking ready?
 * @returns
 */
const trackingReady = async () => {
  return new Promise(resolve => {
    let checkCounter = 0
    /**
     * Check loaded function
     * @returns
     */
    const isGTMLoaded = () => {
      return (
        window.dataLayer &&
        window.dataLayer.filter(l => l.event === 'gtm.load').length
      )
    }
    // Is loaded or not present?
    if (isGTMLoaded() || !GTM_ID) resolve()
    /**
     * Interval to se when loaded...
     */
    let interval = setInterval(() => {
      // Bail if to long...
      if (checkCounter++ === 10) {
        clearInterval(interval)
        resolve()
      }
      // Check GTM loaded...
      if (isGTMLoaded()) {
        clearInterval(interval)
        resolve()
      }
    }, 200)
  })
}

/**
 * Init tracking
 */
export const initTracking = async () => {
  /**
   * Cookiebot init...
   */
  if (typeof window !== 'undefined') {
    window.addEventListener(
      'CookiebotOnLoad',
      function(e) {
        window.cookiebotAccept = true
        if (window.tempDataLayer?.length) {
          window.tempDataLayer.map(event => {
            pushDataLayer(event)
          })
        }
      },
      false
    )
  }
  // await trackingReady()
}
