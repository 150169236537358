import styled, { css } from 'styled-components'
import { typography, colors, mq } from '../../styles/utilities/variables'
import { H1, H2, H3, H4, H5, H6 } from '../../styles/base/globalStyles'

const defaultStyle = () => css`
  font-weight: ${props => (props.light ? 'normal' : 'bold')};
  text-align: ${props => (props.align ? props.align : 'left')};
  line-height: ${props =>
    props.lineHeight ? props.lineHeight : typography.lineHeight};
  ${props =>
    props.color &&
    css`
      color: ${props => colors[props.color]};
    `}
`

const StyledHeading = styled.div``

StyledHeading.h1 = styled.h1`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H1}`}
  @media ${mq.tablet} {
    font-size: ${typography.sizes.xLarge};
  }
  @media ${mq.mobile} {
    font-size: ${typography.sizes.large};
  }
`
StyledHeading.h2 = styled.h2`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H2}`}
  @media ${mq.tablet} {
    font-size: ${typography.sizes.large};
  }
  @media ${mq.mobile} {
    font-size: ${typography.sizes.medium};
  }
`
StyledHeading.h3 = styled.h3`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H3}`}

  @media ${mq.mobile} {
    font-size: ${typography.sizes.base};
  }
`
StyledHeading.h4 = styled.h4`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H4}`}
  @media ${mq.mobile} {
    font-size: ${typography.sizes.base};
  }
`
StyledHeading.h5 = styled.h5`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H5}`}
  @media ${mq.mobile} {
    font-size: ${typography.sizes.base};
  }
`
StyledHeading.h6 = styled.h6`
  ${defaultStyle}
  ${props =>
    props.size ? `font-size: ${typography.sizes[props.size]};` : `${H6}`}
  @media ${mq.mobile} {
    font-size: ${typography.sizes.midBase};
  }
`

export default StyledHeading
