/**
 * All texts
 */
const STRINGS = {
  sv: {
    /**
     * Date
     */
    DATE_FORMAT: 'D MMMM YYYY',

    /**
     * Lang Selector
     */
    LANG_LABEL: 'Språk',
    LANG_SV_SE_TITLE: 'Sverige',
    LANG_FI_FI_TITLE: 'Finland - Finska',
    LANG_SV_FI_TITLE: 'Finland - Svenska',

    /**
     * FAQ
     */
    FAQ_READ_MORE: 'Läs hela svaret',

    /**
     * News
     */
    NEWS_LIST_HEADER: 'Nytt från Föreningsmagasinet',
    NEWS_LIST_LINK: '/foreningsmagasinet/',
    NEWS_LIST_LINK_TEXT: 'Se alla nyheter i Föreningsmagasinet',
    NEWS_SLUG: 'foreningsmagasinet',

    /**
     * GENERAL
     */
    GENERAL_SELECT: 'Välj',
    GENERAL_SHOW_MORE: 'Visa fler',

    /**
     * Delivery
     */
    DELIVERY_INVALID_ADDRESS:
      'Vi kunde tyvärr inte hitta adressen, har du fyllt i den korrekt?',
    DELIVERY_SUGGESTIONS_HEADING: 'Förslag',

    /**
     * Company news
     */
    COMPANY_NEWS_HEADER: 'Aktuellt',
    COMPANY_NEWS_LINK: '/aktuellt/',
    COMPANY_NEWS_LINK_TEXT: 'Se alla nyheter',
    COMPANY_NEWS_LATEST: 'Senaste inläggen',
    COMPANY_NEWS_SLUG: 'aktuellt',

    /**
     * Earn money page
     */
    EARN_MONEY_SLUG: '/tjana-pengar/',

    /**
     * Form
     */
    FORM_SUCCESS_HEADER: 'Tack så mycket!',
    FORM_SUCCESS_TEXT: 'Tack! Vi kontaktar dig snart.',
    FORM_SUCCESS_MORE_INFO_TEXT:
      'För ännu smidigare hantering komplettera gärna med följande uppgifter.',
    FORM_COMPLETE_HEADER: 'Grattis till ett smart val!',
    FORM_COMPLETE_PREAMBLE: 'Vi hörs snart!',
    FORM_SUBMIT_LABEL: 'Skicka',
    FORM_SUBMIT_INTEREST_LABEL: 'Skicka in',
    FORM_COMPLETE_LABEL: 'Komplettera',
    FORM_REMOVE_LABEL: 'Ta bort',
    FORM_ADD_LABEL: 'Lägg till ytterligare en produkt',
    FORM_REQUIRED_TEXT: 'Detta är ett obligatoriskt fält.',
    FORM_ERROR_TITLE: 'Någonting gick fel!',
    FORM_ERROR_TEXT:
      'Var god försök igen lite senare.<br />Har du några frågor kan du kontakta oss på <a href="mailto:info@newbody.se">info@newbody.se</a> eller <a href="tel:031‒7095650">031 ‒ 709 56 50</a>.',
    FORM_SALES_REQUEST_LABEL: 'Ja, vi vill starta en försäljning',
    FORM_SALES_REQUEST_DESCRIPTION:
      'Kontakta mig för att starta upp en försäljning',
    FORM_SAMPLE_REQUEST_LABEL: 'Vi vill ha mer information',
    FORM_SAMPLE_REQUEST_DESCRIPTION:
      'Kontakta mig för mer information om hur det funkar att sälja',
    FORM_BRAND_LABEL: 'Välj det varumärke ni är intresserade av',
    FORM_FIRST_NAME_LABEL: 'Förnamn',
    FORM_FIRST_NAME_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i ett förnamn.',
    FORM_LAST_NAME_LABEL: 'Efternamn',
    FORM_LAST_NAME_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i ett efternamn.',
    FORM_STREET_LABEL: 'Adress',
    FORM_STREET_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i en gatuadress.',
    FORM_POSTAL_CODE_LABEL: 'Postnummer',
    FORM_POSTAL_CODE_MESSAGE:
      'Fältet är obligatoriskt, Vänligen fyll i ett postnummer.',
    FORM_POSTAL_CODE_ONLY_NUMBERS_MESSAGE:
      'Postnummer kan bara innehålla siffror.',
    FORM_POSTAL_CODE_AMOUNT_NUMBERS_MESSAGE:
      'Kolla antalet siffror. Postnummer kan bara ha 5 siffror.',
    FORM_CITY_LABEL: 'Ort/Stad',
    FORM_CITY_MESSAGE: 'Fältet är obligatoriskt. Vänligen fyll i ett ortsnamn.',
    FORM_PHONE_LABEL: 'Telefon',
    FORM_PHONE_PLACEHOLDER: 'Ditt telefonnummer',
    FORM_PHONE_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i ett telefonnummer',
    FORM_PHONE_INVALID_MESSAGE: 'Vänligen fyll i ett korrekt telefonnummer',
    FORM_PHONE_LETTERS_MESSAGE: 'Telefonnnummer kan inte innehpkka bokstäver.',
    FORM_PHONE_DESCRIPTION:
      'Vi behöver ditt telefonnummer för att kontakta dig och underlätta försäljningsstarten.',
    FORM_EMAIL_DESCRIPTION:
      'Vi behöver din e-postadress för underlätta försäljningsstarten.',
    FORM_EMAIL_LABEL: 'E-post',
    FORM_EMAIL_PLACEHOLDER: 'Din e-postadress',
    FORM_EMAIL_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i en e-postadress.',
    FORM_EMAIL_FORMAT_MESSAGE: 'Vänligen fyll i en korrekt e-postadress',
    FORM_EMAIL_AT_SYMBOL_MESSAGE: 'Vänligen inkludera ett @-tecken',
    FORM_EMAIL_PUNCTUATION_MESSAGE:
      'Vänligen använd en punkt istället för ett komma-tecken',
    FORM_ORGANIZATION_LABEL: 'Förening/Skola/Klassnamn',
    FORM_LEAD_CATEGORY_LABEL: 'Vi är en...',
    FORM_LEAD_CATEGORY_MESSAGE: 'Fältet är obligatoriskt.',
    FORM_ORGANIZATION_MESSAGE:
      'Fältet är obligatoriskt. Vänligen fyll i ett namn.',
    FORM_YEAR_LABEL: 'Välj år för inköp',
    FORM_MONTH_LABEL: 'Välj månad för inköp',
    FORM_PERSONAL_INFO_HEADING: 'Personuppgifter',
    FORM_JAN_LABEL: 'Januari',
    FORM_FEB_LABEL: 'Februari',
    FORM_MAR_LABEL: 'Mars',
    FORM_APR_LABEL: 'April',
    FORM_MAY_LABEL: 'Maj',
    FORM_JUN_LABEL: 'Juni',
    FORM_JUL_LABEL: 'Juli',
    FORM_AUG_LABEL: 'Augusti',
    FORM_SEP_LABEL: 'September',
    FORM_OCT_LABEL: 'Oktober',
    FORM_NOV_LABEL: 'November',
    FORM_DEC_LABEL: 'December',
    FORM_CATEGORY_LABEL: 'Kategori',
    FORM_CATEGORY_PLACEHOLDER: 'Sök på kategori...',
    FORM_PRODUCT_LABEL: 'Produkt',
    FORM_PRODUCT_PLACEHOLDER: 'Sök på namn eller produktnummer...',
    FORM_TICKET_LABEL: 'Gå till ärendet',
    FORM_ITEM_PRODUCT_EMPTY:
      'För att välja en orsak behöver du välja en produkt först',
    FORM_ITEM_FROM_PRODUCT_EMPTY:
      'För att välja ny produkt behöver du välja en produkt först',
    FORM_CATEGORY_TITLE: 'Filtrera på kategori',
    // CHANGE
    FORM_CHANGE_SUCCESS_HEADER: 'Bytet registrerat!',
    FORM_CHANGE_SUCCESS_TEXT:
      'Följ länken nedan för att skriva ut din fraktsedel',
    FORM_CHANGE_ITEM_HEADING: 'Välj den produkt som du önskar skicka tillbaka',
    FORM_CHANGE_CAUSE_HEADING: 'Orsak till byte',
    FORM_CHANGE_REPLACEMENT_HEADING:
      'Välj produkt och storlek du vill ha istället',
    FORM_CHANGE_SUBMIT_LABEL: 'Registrera byte',
    // Return
    FORM_RETURN_SUCCESS_HEADER: 'Reklamation registrerad!',
    FORM_RETURN_SUCCESS_TEXT:
      'När en första bedömning är gjord blir du kontaktad via mail',
    FORM_RETURN_ITEM_HEADING: 'Välj den produkt som du önskar reklamera',
    FORM_RETURN_CAUSE_HEADING: 'Orsak till reklamation',
    FORM_RETURN_QUANTITY_LABEL:
      'Antal plagg (Andel felaktiga/trasiga plagg i boxen)',
    FORM_RETURN_IMAGE_HEADING: 'Bifoga bilder på produkter',
    FORM_RETURN_IMAGE_1_LABEL: 'Bild 1: Felet/skadan/problemet på produkten',
    FORM_RETURN_IMAGE_2_LABEL: 'Bild 2: Märkningen i produkten',
    FORM_RETURN_SUBMIT_LABEL: 'Reklamera produkter',

    /**
     * Newsletter
     */
    NEWSLETTER_MESSAGE_SUCCESS: 'Tack så mycket för din prenumeration!',
    NEWSLETTER_CONSENT_TEXT:
      'Jag har tagit del av Newbodys personuppgiftspolicy. <a href="https://www.newbodyfamily.com/sv-se/integritetspolicy/" target="_blank">Läs den här</a>',
    NEWSLETTER_CONSENT_VALIDATION:
      'Du behöver godkänna Newbodys personuppgiftspolicy.',

    FORM_NEWSLETTER_SUCCESS_HEADER: 'Tack för visat intresse!',
    FORM_NEWSLETTER_SUCCESS_TEXT: 'Din e-post är nu registrerad.',
    FORM_NEWSLETTER_LABEL: 'Jag vill få uppdateringar via nyhetsbrev',

    /**
     * Header
     */
    HEADER_INFO_TEXT: 'Starta försäljning',
    HEADER_WEBSHOP_TEXT: 'Webbshop',
    HEADER_WEBSHOP_URL: 'https://www.newbodyfamily.com/sv-se/shop/',
    HEADER_CUSTOMER_TEXT: 'Kundtjänst',
    HEADER_MAGAZINE_TEXT: 'Föreningsmagasinet',
    HEADER_MAGAZINE_URL:
      'https://www.newbodyfamily.com/sv-se/foreningsmagasinet/',
    HEADER_TOURNAMENT_TEXT: 'Cuphittaren',
    HEADER_TOURNAMENT_URL: 'https://www.newbodyfamily.com/sv-se/cuphittaren/',
    HEADER_LOG_IN_TEXT: 'Logga in',
    HEADER_LOG_IN_URL: 'https://portal.newbodyfamily.com/login',
    HEADER_SUSTAINABILITY_URL: '/hallbarhet/',
    HEADER_SUSTAINABILITY_TEXT: 'Hållbarhet',
    HEADER_CART_URL: 'https://www.newbodyfamily.com/sv-se/shop/checkout/',
    FOOTER_ADDRESS_HEADER: 'Hämtlager',
    FOOTER_NEWSLETTER_HEADING: 'Följ vårt nyhetsbrev',
    FOOTER_NEWSLETTER_EMAIL: 'Din epostadress',
    FOOTER_NEWSLETTER_SEND: 'Skicka',
    FOOTER_UC_TEXT:
      'Sigillet är utfärdat av UC AB. Klicka på bilden för information om UC:s Riskklasser.',
    FOOTER_UC_IMAGE:
      'https://www.uc.se/ucsigill2/sigill?org=556414-8053&language=swe&product=lsa&special=&fontcolor=w&type=svg',
    FOOTER_UC_LINK:
      'https://www.uc.se/risksigill2/?showorg=556414-8053&language=swe&special=',
    FOOTER_INSTAGRAM_LINK: 'https://www.instagram.com/newbodyfamily/',
    FOOTER_FACEBOOK_LINK: 'https://www.facebook.com/newbodyfamily/',
    /**
     * Seller
     */
    SELLER_SELL_WITH_US: 'Starta försäljning',
    SELLER_LINK: 'https://www.newbodyfamily.com/sv-se/intresseanmalan/',

    /**
     * Custom reviews
     */
    CUSTOM_REVIEWS_START: 'Vad några av våra ',
    CUSTOM_REVIEWS_END: ' nöjda kunder säger.',
  },

  fi: {
    /**
     * Date
     */
    DATE_FORMAT: 'D. MMMM YYYY',

    /**
     * Lang Selector
     */
    LANG_LABEL: 'Kieli',
    LANG_SV_SE_TITLE: 'Ruotsi',
    LANG_FI_FI_TITLE: 'Suomi – Suomi',
    LANG_SV_FI_TITLE: 'Suomi – Ruotsi',

    /**
     * FAQ
     */
    FAQ_READ_MORE: 'Lue koko vastaus',

    /**
     * Company news
     */
    COMPANY_NEWS_HEADER: 'Ajankohtaista',
    COMPANY_NEWS_LINK: '/ajankohtaista/',
    COMPANY_NEWS_LINK_TEXT: 'Katso kaikki tiedotteemme',
    COMPANY_NEWS_LATEST: 'Viimeisin viesti',
    COMPANY_NEWS_SLUG: 'ajankohtaista',

    /**
     * GENERAL
     */
    GENERAL_SELECT: 'Valitse',
    GENERAL_SHOW_MORE: 'Näytä Lisää',

    /**
     * Delivery
     */
    DELIVERY_INVALID_ADDRESS:
      'Osoitetta ei valitettavasti löytynyt, kirjoititko sen oikein?',
    DELIVERY_SUGGESTIONS_HEADING: 'Ehdotukset',

    /**
     * Form
     */
    FORM_SUCCESS_HEADER: 'Kiitos!',
    FORM_SUCCESS_TEXT: 'Otamme sinuun yhteyttä mahdollisimman pian!',
    FORM_SUCCESS_MORE_INFO_TEXT:
      'Jotta käsittely olisi entistä sujuvampaa, täytä tietosi seuraavilla tiedoilla.',
    FORM_SUBMIT_LABEL: 'Lähetä',
    FORM_SUBMIT_INTEREST_LABEL: 'Lähetä',
    FORM_COMPLETE_HEADER: 'Kiitos nähdään pian!',
    FORM_COMPLETE_PREAMBLE:
      'Jotta käsittely olisi entistä sujuvampaa, täytä tietosi seuraavilla tiedoilla.',
    FORM_COMPLETE_LABEL: 'komplementti',
    FORM_REMOVE_LABEL: 'Poista',
    FORM_ADD_LABEL: 'Liitä toinen tuote',
    FORM_REQUIRED_TEXT: 'Tämä kenttä on pakolinen.',
    FORM_ERROR_TITLE: 'Jotakin meni pieleen!',
    FORM_ERROR_TEXT:
      'Ole hyvä ja yritä myöhemmin uudelleen. Jos sinulla on kysyttävää, voit ottaa meihin yhteyttä osoitteella <a href="mailto:info@newbody.fi">info@newbody.fi</a> tai soittamalla numeroon <a href="tel:075 – 325 84 40">075 – 325 84 40</a>.',
    FORM_SALES_REQUEST_LABEL: 'Haluamme aloittaa myynnin',
    FORM_SALES_REQUEST_DESCRIPTION: 'Ota yhteyttä niin aloitetaan myynti',
    FORM_SAMPLE_REQUEST_LABEL: 'Haluamme lisätietoa ja ilmaisen näytteen',
    FORM_SAMPLE_REQUEST_DESCRIPTION:
      'Ota yhteyttä saadaksesi lisätietoja myynnin toiminnasta.',
    FORM_BRAND_LABEL: 'Valitse tuotemerkki joka teitä kiinostaa',
    FORM_FIRST_NAME_LABEL: 'Etunimi',
    FORM_FIRST_NAME_MESSAGE: 'Kenttä on pakollinen. Ole hyvä ja täytä etunimi',
    FORM_LAST_NAME_LABEL: 'Sukunimi',
    FORM_LAST_NAME_MESSAGE: 'Kenttä on pakollinen. Ole hyvä ja täytä sukunimi',
    FORM_STREET_LABEL: 'Osoite',
    FORM_STREET_MESSAGE: 'Kenttä on pakollinen. Ole hyvä ja täytä osoite',
    FORM_POSTAL_CODE_LABEL: 'Postinumero',
    FORM_POSTAL_CODE_MESSAGE:
      'Kenttä on pakollinen. Ole hyvä ja täytä postinumero',
    FORM_POSTAL_CODE_ONLY_NUMBERS_MESSAGE:
      'Postinumero voi sisältää vain numeroita',
    FORM_POSTAL_CODE_AMOUNT_NUMBERS_MESSAGE:
      'Tarkista numeroiden määrä. Postinumero voi sisältää vain 5 numeroa.',
    FORM_CITY_LABEL: 'Postitoimipaikka',
    FORM_CITY_MESSAGE:
      'Kenttä on pakollinen. Ole hyvä ja täytä postitoimipaikka',
    FORM_PHONE_LABEL: 'Puhelinnumero',
    FORM_PHONE_PLACEHOLDER: 'Puhelinnumerosi',
    FORM_PHONE_MESSAGE: 'Kenttä on pakollinen. Ole hyvä ja täytä puhelinnumero',
    FORM_PHONE_INVALID_MESSAGE: 'Anna oikea puhelinnumero.',
    FORM_PHONE_LETTERS_MESSAGE: 'Puhelinnumero ei voi sisältää kirjaimia.',
    FORM_PHONE_DESCRIPTION:
      'Tarvitsemme puhelinnumerosi, jotta voimme ottaa sinuun yhteyttä ja helpottaa myynnin aloittamista.',
    FORM_EMAIL_LABEL: 'Sähköpostiosoite',
    FORM_EMAIL_PLACEHOLDER: 'Sähköpostiosoitteesi',
    FORM_EMAIL_MESSAGE:
      'Kenttä on pakollinen. Ole hyvä ja lisää sähköpostiosoitteesi.',
    FORM_EMAIL_FORMAT_MESSAGE: 'Ole hyvä ja täytä oikea sähköpostiosoite',
    FORM_EMAIL_AT_SYMBOL_MESSAGE: 'Sisällytä @-merkki',
    FORM_EMAIL_PUNCTUATION_MESSAGE:
      'Käytä pistettä pilkun sijaan sähköpostiosoitteessa',
    FORM_EMAIL_DESCRIPTION:
      'Tarvitsemme sähköpostiosoitteesi myynnin aloittamista varten.',
    FORM_ORGANIZATION_LABEL: 'Yhdistys/Koulu/Luokan nimi',
    FORM_LEAD_CATEGORY_LABEL: 'Olemme...',
    FORM_ORGANIZATION_MESSAGE: 'Kenttä on pakollinen. Ole hyvä ja täytä nimi',
    FORM_YEAR_LABEL: 'Valitse oston vuosi',
    FORM_MONTH_LABEL: 'Valitse oston kuukausi',
    FORM_PERSONAL_INFO_HEADING: 'Henkilötiedot',
    FORM_JAN_LABEL: 'Tammikuu',
    FORM_FEB_LABEL: 'Helmikuu',
    FORM_MAR_LABEL: 'Maaliskuu',
    FORM_APR_LABEL: 'Huhtikuu',
    FORM_MAY_LABEL: 'Toukokuu',
    FORM_JUN_LABEL: 'Kesäkuu',
    FORM_JUL_LABEL: 'Heinäkuu',
    FORM_AUG_LABEL: 'Elokuu',
    FORM_SEP_LABEL: 'Syyskuu',
    FORM_OCT_LABEL: 'Lokakuu',
    FORM_NOV_LABEL: 'Marraskuu',
    FORM_DEC_LABEL: 'Joulukuu',
    FORM_PRODUCT_LABEL: 'Tuotteen nimi',
    FORM_PRODUCT_PLACEHOLDER: 'Etsi nimellä tai tuotenumerolla…',
    FORM_TICKET_LABEL: 'Siirry lomakkeeseen',
    FORM_ITEM_PRODUCT_EMPTY:
      'Jos haluat valita syyn, sinun on ensin valittava tuote',
    FORM_ITEM_FROM_PRODUCT_EMPTY:
      'Jos haluat valita uuden tuotteen, sinun on ensin valittava tuote',
    FORM_CATEGORY_TITLE: 'Suodata luokan mukaan',
    // // CHANGE
    FORM_CHANGE_SUCCESS_HEADER: 'Vaihto rekisteröity!',
    FORM_CHANGE_SUCCESS_TEXT:
      'Seuraa alla olevaa linkkiä tehdäksesi rahtikirjan',
    FORM_CHANGE_ITEM_HEADING: 'Valitse tuote, jonka haluat palauttaa',
    FORM_CHANGE_CAUSE_HEADING: 'Palautuksen syy',
    FORM_CHANGE_REPLACEMENT_HEADING:
      'Valitse tuote ja koko, jonka haluat tilalle',
    FORM_CHANGE_SUBMIT_LABEL: 'Lähetä palautus',
    // // Return
    FORM_RETURN_SUCCESS_HEADER: 'Reklamaatio rekisteröity!',
    FORM_RETURN_SUCCESS_TEXT:
      'Ensimmäisen arvioinnin jälkeen saat viestin sähköpostitse',
    FORM_RETURN_ITEM_HEADING: 'Valitse tuote, jonka haluat palauttaa',
    FORM_RETURN_CAUSE_HEADING: 'Reklamaation syy',
    FORM_RETURN_QUANTITY_LABEL:
      'Vaatteiden lukumäärä (viallisten vaatteiden lukumäärä pakkauksessa)',
    FORM_RETURN_IMAGE_HEADING: 'Lataa kuvia tuotteesta',
    FORM_RETURN_IMAGE_1_LABEL: 'Tuotteessa oleva vika/vaurio/ongelma',
    FORM_RETURN_IMAGE_2_LABEL: 'Tuotemerkintä',
    FORM_RETURN_SUBMIT_LABEL: 'Lähetä reklamaatio',

    /**
     * Newsletter
     */
    FORM_NEWSLETTER_SUCCESS_HEADER: 'Kiitos mielenkiinnostasi!',
    FORM_NEWSLETTER_SUCCESS_TEXT: 'Sähköpostiosoitteesi on rekisteröity.',
    NEWSLETTER_MESSAGE_SUCCESS: 'Kiitos paljon tilauksestasi!',

    /**
     * Header
     */
    HEADER_INFO_TEXT: 'Aloita myynti',
    HEADER_WEBSHOP_TEXT: 'Nettikauppa',
    HEADER_WEBSHOP_URL: 'https://www.newbodyfamily.com/fi-fi/shop/',
    HEADER_CUSTOMER_TEXT: 'Asiakaspalvelu',
    HEADER_MAGAZINE_TEXT: 'Lehti',
    //  HEADER_TOURNAMENT_TEXT: 'Cuphittaren',
    HEADER_LOG_IN_TEXT: 'Kirjaudu sisään',
    HEADER_LOG_IN_URL: 'https://portal.newbodyfamily.com/login',
    HEADER_CART_URL: 'https://www.newbodyfamily.com/fi-fi/shop/checkout/',

    /**
     * Footer
     */
    FOOTER_ADDRESS_HEADER: 'Osoite',
    FOOTER_NEWSLETTER_HEADING: 'Tilaa uutiskirjeemme',
    FOOTER_NEWSLETTER_EMAIL: 'Sähköpostiosoitteesi',
    FOOTER_NEWSLETTER_SEND: 'Lähetä',
    FOOTER_UC_TEXT:
      'Sinetin on myöntänyt UC AB. Napsauta kuvaa saadaksesi tietoja UC:n riskiluokista.',
    FOOTER_UC_IMAGE:
      'https://www.uc.se/ucsigill2/sigill?org=556414-8053&language=fi&product=lsa&special=&fontcolor=w&type=svg',
    FOOTER_UC_LINK:
      'https://www.uc.se/en/risksigill2/?showorg=556414-8053&language=fi&special=',
    FOOTER_INSTAGRAM_LINK: 'https://www.instagram.com/newbodysuomi/',
    FOOTER_FACEBOOK_LINK: 'https://www.facebook.com/NewbodySuomi/',

    /**
     * Seller
     */
    SELLER_SELL_WITH_US: 'Sälj med oss',
    SELLER_LINK: 'https://www.newbodyfamily.com/fi-fi/olenkiinnostunut/',
    /**
     * Custom reviews finnish
     */
    CUSTOM_REVIEWS_START: 'Mitä muutama meidän ',
    CUSTOM_REVIEWS_END: ' tyytyväinen asiakas sanoo.',
  },
  sv_fi: {
    HEADER_WEBSHOP_URL: 'https://www.newbodyfamily.com/sv-fi/shop/',
    HEADER_LOG_IN_URL: 'https://portal.newbodyfamily.com/login',
    HEADER_CART_URL: 'https://www.newbodyfamily.com/sv-fi/shop/checkout/',
    /**
     * Footer
     */
    FOOTER_ADDRESS_HEADER: 'Adress',
  },
}

/**
 * Get string for lang
 * @param {*} key
 */
const getString = key => {
  const currentLang = getCurrentLang()
  const string = STRINGS[currentLang][key]
  // SV FI specific string?
  if (
    process.env.GATSBY_PATH_PREFIX &&
    process.env.GATSBY_PATH_PREFIX === '/sv-fi/' &&
    STRINGS['sv_fi'][key]
  )
    return STRINGS['sv_fi'][key]
  // Default
  if (!string && currentLang !== 'sv') return STRINGS['sv'][key]
  // Match
  return string
}

/**
 * Get current lang
 */
const getCurrentLang = () => {
  return process.env.GATSBY_CURRENT_LANG
    ? process.env.GATSBY_CURRENT_LANG
    : 'sv'
}

/**
 * Show language switcher
 */
const getShowLanguageSwitcher = () => {
  return process.env.GATSBY_LANGUAGE_SWITCHER
    ? process.env.GATSBY_LANGUAGE_SWITCHER
    : false
}

/**
 * Language Switcher
 */
const languageSwitcher = {
  label: getString('LANG_LABEL'),
  links: [
    /*{
      link: {
        title: getString('LANG_SV_SE_TITLE'),
        url: 'https://www.newbodyfamily.com/sv-se/',
      },
    },*/
    {
      link: {
        title: getString('LANG_FI_FI_TITLE'),
        langCode: 'fi',
        url: 'https://www.newbodyfamily.com/fi-fi/',
      },
    },
    {
      link: {
        title: getString('LANG_SV_FI_TITLE'),
        langCode: 'sv',
        url: 'https://www.newbodyfamily.com/sv-fi/',
      },
    },
  ],
}

const INGRID_LOCALE = process.env.GATSBY_INGRID_LOCALE
  ? process.env.GATSBY_INGRID_LOCALE
  : 'sv_SE'

module.exports = {
  getString,
  languageSwitcher,
  getShowLanguageSwitcher,
  getCurrentLang,
  INGRID_LOCALE,
}
