import { createGlobalStyle } from 'styled-components'
import { colors, typography, spacing, mq } from '../utilities/variables'

export const H1 = `
  font-size: ${typography.sizes.xxLarge};
  line-height: 1.1;
  @media ${mq.tablet} {
    font-size: ${typography.sizes.large};
  }
`
export const H2 = `
  font-size: ${typography.sizes.xLarge};
  line-height: 1.1;
  @media ${mq.tablet} {
    font-size: ${typography.sizes.medium};
  }
`
export const H3 = `
  font-size: ${typography.sizes.large};
`
export const H4 = `
  font-size: ${typography.sizes.medium};
`
export const H5 = `
  font-size: ${typography.sizes.base};
`
export const H6 = `
  font-size: ${typography.sizes.small};
`

export const GlobalStyle = createGlobalStyle`

  /* Inter, Material Icons and typekit moved to gatsby-config.js */  

  html,
  body {
    font-family: ${typography.primaryFont};

    @media ${mq.tablet} {
      font-size: 50%;
    }

    @media ${mq.mobile} {
      font-size: 60%;
    }
  }
  
  body {
    color: ${colors.black};
    font-size: ${typography.sizes.small};
  }

  input {
    font-size: ${typography.sizes.small};
  }
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: ${spacing.small};
  }
  
  h1,h2,h3,h4,h5,h6,strong {
    font-weight: bold;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${typography.secondaryFont};
  }

  h1 {
    ${H1}
  }
  h2 {
    ${H2}
  }
  h3 {
    ${H3}
  }
  h4 {
    ${H4}
  }
  h5 {
    ${H5}
  }
  h6 {
    ${H6}
  }

  code, kbd, pre, samp {
    font-family: monospace;
    background: #e6e6e6;
    padding: .1em .2em;
    margin: 0.1em;
    padding: .25ex .75ch;
}

#CookiebotWidget {
  @media ${mq.mobileSmall} {
      display: none;
    }
}
`
