import dayjs from 'dayjs'
import queryString from 'query-string'
import 'dayjs/locale/sv'
import 'dayjs/locale/fi'
import { getCurrentLang, getString } from './lang'
import { fetchEmailLog } from './api'
import { PATH_PREFIX } from './url'
import { createHash } from 'crypto'

/**
 * Format date string
 * @param {*} string
 */
export const formatDate = string => {
  if (!string) return null
  const date = dayjs(string)
  const dateFormat = getString('DATE_FORMAT') // 'D MMMM YYYY'
  return date.locale(getCurrentLang()).format(dateFormat)
}

export const logger = string => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(string)
  }
}

export const parseVerticalAlign = align => {
  switch (align) {
    case 'center':
      return 'center'
    case 'top':
      return 'flex-start'
    case 'bottom':
      return 'flex-end'
    default:
  }
}

/**
 * Ger query params
 */
export const getQueryParams = () => {
  const parsed =
    typeof window !== 'undefined' && window.location
      ? queryString.parse(window.location.search)
      : {}
  return parsed
}

export const isPreview = () => {
  const { nonce } = getQueryParams()
  if (nonce) return true
  return false
}

/**
 * Sort by key
 * @param {*} key
 * @param {*} order
 */
export const sortByKey = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export const getIsClient = () => {
  return typeof window === 'object'
}

/**
 * Send errors to sentry
 * @param {*} error
 */
export const catchError = (error, data = {}) => {
  logger(error)
  if (getIsClient() && typeof window.Sentry !== 'undefined') {
    window.Sentry.captureException(error, {
      extra: {
        formData: JSON.stringify(data),
      },
    })
  }
}

/**
 * Send interest form error
 */
export const sendInterestFormError = async ({
  email = '',
  phone = '',
  firstName = '',
  lastName = '',
  organisation = '',
  message = ''
}) => {
  if (getIsClient()) {
    try {
      fetchEmailLog({
        subject: 'Fel från intresseformulär',
        message: `E-post: ${email}, tel: ${phone}, namn: ${firstName} ${lastName}, org: ${organisation} - Fel när intresse skulle skapas. Meddelande: ${message}`,
      })
    } catch (e) {}
  }
}

/**
 * Send change form error
 * @param {*} email
 */
export const sendChangeFormError = async email => {
  if (getIsClient()) {
    try {
      fetchEmailLog({
        subject: 'Fel från bytesformulär',
        message: `Person med e-post ${email} fick ett fel när byte skulle skapas.`,
      })
    } catch (e) {}
  }
}

/**
 * Send return form error
 * @param {*} email
 */
export const sendReturnFormError = async email => {
  if (getIsClient()) {
    try {
      fetchEmailLog({
        subject: 'Fel från returformulär',
        message: `Person med e-post ${email} fick ett fel när retur skulle skapas.`,
      })
    } catch (e) {}
  }
}

export const NB_SHOP_URL = process.env.GATSBY_NB_SHOP_URL || null

export const currentPathWithoutPrefix = () => {
  const currentPage = getIsClient() ? window.location.pathname : ''
  const currentPageWithoutPrefix = currentPage.replace(PATH_PREFIX, '/')
  return currentPageWithoutPrefix
}

/**
 * Hash string
 * @param {*} string
 * @returns
 */
export const hash = string => {
  if (string === null || string === undefined) return null
  return createHash('sha256')
    .update(string)
    .digest('hex')
}
