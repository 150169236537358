/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getCurrentLang } from '../../helpers/lang'
import { CMS_URL } from '../../../node/functions'
// Pre load fonts
import FaLight from '../../../static/fonts/fa-light-300.woff2'
import GalanogrotesqueLight from '../../../static/fonts/Galanogrotesque-Light.woff'
import GalanogrotesqueMedium from '../../../static/fonts/Galanogrotesque-Medium.woff'
import GalanogrotesqueRegular from '../../../static/fonts/Galanogrotesque-Regular.woff'
import GalanogrotesqueSemibold from '../../../static/fonts/Galanogrotesque-Semibold.woff'
import MatrisePro from '../../../static/fonts/MatrisePro.woff'

function SEO({
  title,
  description,
  keywords,
  canonical,
  openGraphType,
  openGraphTitle,
  openGraphDescription,
  openGraphImage,
  metaRobotsNoindex,
  metaRobotsNofollow,
  //lang = 'en',
  schema,
  meta,
  preloadImage,
  hrefLangs,
}) {
  const currentLang = getCurrentLang()
  // Important to set correct type ex: 'font/woff'
  const preLoadFonts = [
    { font: GalanogrotesqueLight, type: 'font/woff' },
    { font: GalanogrotesqueMedium, type: 'font/woff' },
    { font: GalanogrotesqueSemibold, type: 'font/woff' },
    { font: GalanogrotesqueRegular, type: 'font/woff' },
    { font: MatrisePro, type: 'font/woff' },
    { font: FaLight, type: 'font/woff2' },
  ]
  const _hrefLangs = hrefLangs ? hrefLangs : []
  const noIndex = process.env.GATSBY_NO_INDEX ? true : false
  return (
    <Helmet
      htmlAttributes={{
        currentLang: currentLang,
        lang: currentLang,
      }}
      title={title}
      keywords={keywords}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: openGraphTitle,
        },
        {
          property: `og:description`,
          content: openGraphDescription,
        },
        {
          property: `og:type`,
          content: openGraphType,
        },
        {
          property: `og:image`,
          content: openGraphImage,
        },
        {
          name: `twitter:title`,
          content: openGraphTitle,
        },
        {
          name: `twitter:description`,
          content: openGraphDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `robots`,
          content: metaRobotsNoindex
            ? `${metaRobotsNoindex}, ${metaRobotsNofollow}`
            : null,
        },
      ].filter(m => m && m.content)}
    >
      {canonical && <link rel='canonical' href={canonical} />}

      <link rel='icon' type='image/svg' href='favicon.svg' sizes='16x16' />

      {CMS_URL && CMS_URL !== '' && <link rel='dns-prefetch' href={CMS_URL} />}
      {noIndex && <meta name='robots' content='noindex' />}

      {preLoadFonts.map(({ font, type }) => {
        return (
          <link
            rel='preload'
            as='font'
            href={font}
            type={type}
            crossOrigin='anonymous'
          />
        )
      })}
      {preloadImage && <link rel='preload' as='image' {...preloadImage} />}
      {_hrefLangs.map(hrefLang => {
        return (
          <link
            rel='alternate'
            href={hrefLang.href}
            hreflang={hrefLang.hreflang}
          />
        )
      })}
      {schema && (
        <script type='application/ld+json'>{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
