import styled, { css } from "styled-components"
import { spacing, mq } from "../../styles/utilities/variables"
import StyledForm from "../Form/style"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : null)};
  justify-content: ${props => (props.justify ? props.justify : null)};
  cursor: ${props => (props.onClick ? "pointer" : null)};
  min-width: ${props => (props.width ? props.width + "px" : "")};

  /** gap */
  row-gap: ${props => (props.rowGap ? spacing[props.rowGap] : "")};
  column-gap: ${props => (props.columnGap ? spacing[props.columnGap] : "")};
  gap: ${props => (props.gap ? spacing[props.gap] : "")};

  @media ${mq.mobile} {
    min-width: ${props => (props.width ? "inherit" : null)};
  }


  ${props =>
    props.spacing &&
    css`
      & > :not(:last-child) {
        ${props =>
          props.direction === "column"
            ? `margin-bottom: ${spacing[props.spacing]}`
            : `margin-right: ${spacing[props.spacing]}`};

        @media ${mq.mobile} {
          ${props =>
            props.direction === "column"
              ? `margin-bottom: ${spacing.small}`
              : `margin-right: ${spacing.small}`};
        }
      }
    `}

  ${props =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;

      /* & > * {
        @media ${mq.mobile} {
          ${props =>
            props.spacing
              ? `margin: ${spacing[props.spacing]} !important`
              : `margin: ${spacing.small}`};
        }
      } */
    `}

    ${props =>
      props.responsive &&
      css`
        @media ${mq.mobile} {
          flex-direction: column;

          & > * {
            width: 100%;
          }

          & > :not(:last-child) {
            margin-right: 0;
            margin-bottom: ${spacing.small};
          }
        }
      `}
`

export default StyledWrapper
